<template>
  <div class="news_whole">
    <Nav></Nav>
    <div class="news-con">
      <div class="news-title">
        <div class="new-text">
          <div class="title"></div>
          <div class="text">最新图片</div>
        </div>
        <div class="line"></div>
      </div>
      <div class="news-list">
        <div v-for="p in navList" :key="p.id" class="whole_box">
          <div class="whole_box_content">
            <div
              class="img"
              :style="{ backgroundImage: `url(` + p.imgURL + `)` }"
              @click="onlookPost(p.id)"
            ></div>
            <div class="title_s" @click="onlookPost(p.id)">
              {{ p.title }}
            </div>
            <div class="eventTime">{{ p.onnetTime }}</div>
          </div>
        </div>
      </div>
      <div class="news-bottom">
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="20"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="downTotal"
          ></el-pagination>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      navList: [], // 二级的稿件
      page: 1,
      currentPage: 1,
      downTotal: 1,
    };
  },
  created() {
    console.log("ds1", this.$route.query);
    this.reload();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next();
      this.reload();
    }
  },
  methods: {
    reload() {
      var thiz = this;
      var query = {};
      query.storeIds = [1];
      query.order = 2;
      var nowTime = new Date();
      query.uploadTo =
        nowTime.getFullYear() +
        "-" +
        ("" + (nowTime.getMonth() + 1)).padStart(1, "0") +
        "-" +
        ("" + nowTime.getDate()).padStart(1, "0");
      var after = new Date(nowTime - 100 * 24 * 3600 * 1000);
      query.uploadFrom =
        after.getFullYear() +
        "-" +
        ("" + (after.getMonth() + 1)).padStart(1, "0") +
        "-" +
        ("" + after.getDate()).padStart(1, "0");
      RD.pure()
        .query()
        .query(query, thiz.page, 20)
        .then((items) => {
          items.list.forEach((k) => {
            k.imgURL = RD.pic().id(k.frontPicId).sImage_URL();
          });
          thiz.navList = items.list;
          thiz.currentPage = items.pager.currentPage;
          thiz.downTotal = items.pager.total;
        });
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this;
      thiz.page = val;
      thiz.currentPage = val;
      thiz.reload(thiz.page);
    },
    // 查看稿件
    onlookPost(id) {
      this.$router.push({
        path: "/post_" + postId + ".html",
      });
    },
  },
};
</script>

<style scoped lang="less">
.news_whole {
  background-color: #fff;
}
@media all and (max-width: 800px) {
  .news-con {
    width: 750px;
    height: 100%;
    margin: 0 auto;
    .news-title {
      height: 50px;
      width: 100%;
      .new-text {
        width: 50%;
        text-align: left;
        margin-left: 0.5rem;
        font-size: 14px;
        display: flex;
        padding-top: 1rem;
        .title {
          width: 32px;
          height: 32px;
          background: no-repeat url(../assets/camera.png);
          background-size: cover;
        }
        .text {
          margin-left: 5px;
          font-size: 16px;
          line-height: 32px;
        }
      }
      .line {
        width: 100%;
        height: 0.2rem;
        margin-top: 1rem;
        margin-left: 0.5rem;
        background-color: #bfbfbf;
      }
    }
    .news-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      margin-top: 20px;
      .whole_box {
        height: 170px;
        width: 170px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin: 1rem;
        .whole_box_content {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .img {
            width: 100%;
            height: 75%;
            transition: all 0.6s;
            display: block;
            // z-index: 10;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #f6f6f6;
            cursor: pointer;
          }
          .title_s {
            height: 8%;
            transition: all 0.5s linear;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 1.5rem;
            text-align: left;
            font-size: 1.4rem;
            left: 0;
            right: 0;
            margin-left: 1rem;
            line-height: 2.5rem;
            cursor: pointer;
          }
          .eventTime {
            height: 10%;
            text-align: left;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .time {
            height: 10%;
            position: absolute;
            left: 13.5rem;
            .count {
              height: 2rem;
              display: inline-block;
            }
            .tag {
              display: inline-block;
              height: 2rem;
              width: 2rem;
              cursor: pointer;
              margin-left: 1rem;
              //   background: no-repeat url(../assets/tagup.png);
            }
            .like {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              //   background: no-repeat url(../assets/like.png);
            }
            .com {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              background: no-repeat url(../assets/comment.png);
            }
          }
        }
      }
    }
    .news-bottom {
      height: 8rem;
      width: 100%;
      margin-top: 4rem;
      position: relative;
      .page-container {
        position: absolute;
        right: 5rem;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .news-con {
    width: 120rem;
    height: 100%;
    margin: 0 auto;
    .news-title {
      height: 7rem;
      width: 100%;
      .new-text {
        width: 50%;
        text-align: left;
        margin-left: 0.5rem;
        font-size: 1.4rem;
        display: flex;
        padding-top: 1rem;
        .title {
          width: 5rem;
          height: 5rem;
          background: no-repeat url(../assets/camera.png);
          background-size: cover;
        }
        .text {
          margin-left: 0.5rem;
          font-size: 1.8rem;
          line-height: 5rem;
        }
      }
      .line {
        width: 100%;
        height: 0.2rem;
        margin-top: 1rem;
        margin-left: 0.5rem;
        background-color: #bfbfbf;
      }
    }
    .news-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      margin-top: 2rem;
      .whole_box {
        height: 28rem;
        width: 28rem;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        margin: 1rem;
        .whole_box_content {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .img {
            width: 100%;
            height: 75%;
            transition: all 0.6s;
            display: block;
            // z-index: 10;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #f6f6f6;
            cursor: pointer;
          }
          .title_s {
            height: 8%;
            transition: all 0.5s linear;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-top: 1.5rem;
            text-align: left;
            font-size: 14px;
            left: 0;
            right: 0;
            margin-left: 1rem;
            line-height: 2.5rem;
            cursor: pointer;
          }
          .eventTime {
            height: 10%;
            text-align: left;
            margin-left: 1rem;
            margin-top: 0.5rem;
          }
          .time {
            height: 10%;
            position: absolute;
            left: 13.5rem;
            .count {
              height: 2rem;
              display: inline-block;
            }
            .tag {
              display: inline-block;
              height: 2rem;
              width: 2rem;
              cursor: pointer;
              margin-left: 1rem;
              //   background: no-repeat url(../assets/tagup.png);
            }
            .like {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              //   background: no-repeat url(../assets/like.png);
            }
            .com {
              height: 2rem;
              display: inline-block;
              cursor: pointer;
              margin-left: 1rem;
              width: 2rem;
              background: no-repeat url(../assets/comment.png);
            }
          }
        }
      }
      .banmian_box {
        height: 48.5rem;
        .whole_box_content {
          .img {
            height: 82%;
            background-size: contain;
          }
        }
      }
    }
    .news-bottom {
      height: 8rem;
      width: 100%;
      margin-top: 4rem;
      position: relative;
      .page-container {
        position: absolute;
        right: 5rem;
      }
    }
  }
}
</style>
